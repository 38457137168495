/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import UpdateIcon from '@material-ui/icons/Update'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import CircularProgress from '@material-ui/core/CircularProgress'
import SideBar from '../components/SideBar'
import GLOBAL from '../Global'
import GoogleAdFooter from '../utils/GoogleAdFooter'
import GoogleAdHeader from '../utils/GoogleAdHeader'

class ContentsSingle extends Component {
  setTitle = data => {
    // const url = `https://midknitemagazine.com/contents/${data.slug}`
    const description = data.text
      .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
      .replace(/\s+/g, '')
    document.title = `${data.title} | ${GLOBAL.SITENAME}`
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', description)
    // document
    //   .querySelector('meta[name="twitter:title"]')
    //   .setAttribute('content', data.title)
    // document
    //   .querySelector('meta[name="twitter:description"]')
    //   .setAttribute('content', description)
    // document
    //   .querySelector('meta[name="twitter:image"]')
    //   .setAttribute('content', data.thumbnailUrl)
  }

  setName = e => {
    switch (e) {
      case 'midkniteeuro':
        return 'MID KNITE €URO'
      case 'midkniteusa':
        return 'MID KNITE U$A'
      case 'midkniteasia':
        return 'MID KNITE ASIA'
      case 'midknitepacific':
        return 'MID KNITE PACIFIC'
      case 'midknitelatin':
        return 'MID KNITE LATIN'
      case 'midkniteafrica':
        return 'MID KNITE AFRICA'
      case 'midknitejapan':
        return 'MID KNITE JAPAN'
      case 'midkniteblue':
        return 'MID KNITE BLUE'
      case 'bleudetokyo':
        return 'BLEU DE TOKYO'
      default:
        return e
    }
  }

  render() {
    const {
      props: { category, data, history },
    } = this
    if (!data)
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )

    this.setTitle(data)

    let image
    if (data.image !== '') {
      image = data.imageUrl
    } else {
      image = 'img-default.png'
    }
    const description = data.text
      .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
      .replace(/\s+/g, '')
      .substring(0, 50) + '...'

    return (
      <div className={`single ${category}`}>
        <Helmet
          title={data.title}
          meta={[
            { property: 'og:image', content: data.thumbnailUrl },
            { property: 'og:title', content: data.title },
            { property: 'og:description', content: description },
            {
              property: 'og:url',
              content: `https://midknitemagazine.com/contents/${data.slug}`
            },
            // { name: 'twitter:title', content: data.title },
            // { name: 'twitter:description', content: description },
            { name: 'twitter:image', content: data.thumbnailUrl },
            {
              name: 'description',
              content: data.text,
            },
          ]}
        />
        <Button
          variant="contained"
          style={{ background: '#2c2c2c', color: 'white', margin: '0 0 10px' }}
          onClick={() => history.goBack()}
          className="backButton"
        >
          <ArrowBackIosIcon />
          BACK
        </Button>
        <div className="mainHeading">
          <h1>{data.title}</h1>
        </div>
        <div className="mainContent">
          <div className="mainContainer">
            <div
              className={`mainBody ${data.brandCategory &&
                data.brandCategory[0]}`}
            >
              <div className="tagContainer">
                {data.areaCategory[0] !== '' &&
                  data.areaCategory.map(e => (
                    <span key={e} className={`tag ${e}`}>
                      {this.setName(e)}
                    </span>
                  ))}
              </div>
              <div className="countryContainer">
                {data.countryCategory[0] !== '' &&
                  data.countryCategory.map(e => (
                    <span key={e} className="countryName">
                      <img
                        src={`/images/flags/${e
                          .toLowerCase()
                          .replace(/\s+/g, '')}.png`}
                        alt={e}
                      />
                      {e}
                    </span>
                  ))}
              </div>
              <div className="dateCreated">
                <UpdateIcon /> {data.date} UPDATED
              </div>
              {data.image && (
                <div className="mainVisual">
                  <div className="tagContainer">
                    <div className={`tag ${data.brandCategory[0]}`}>
                      {data.tag}
                    </div>
                  </div>
                  <img src={image} alt={data.title} />
                </div>
              )}
              <div className="adBox">
                <GoogleAdHeader />
              </div>
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: data.text }}
              />
              <div
                className="freeHtml"
                dangerouslySetInnerHTML={{ __html: data.freeHtml }}
              />
              <div className="adBox">
                <GoogleAdFooter />
              </div>
            </div>
            <SideBar />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ContentsSingle)

ContentsSingle.propTypes = {
  data: PropTypes.object,
  category: PropTypes.string,
  history: PropTypes.object.isRequired,
}

ContentsSingle.defaultProps = {
  data: null,
  category: null,
}
