import React, { useEffect } from 'react'

export default function GoogleAdFooter() {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== 'development') {
      window.adsbygoogle.push({})
    }
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-2489395882102764"
      data-ad-slot="5474242203"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  )
}
