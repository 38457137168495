// /* global localStorage */
// /* global performance */

import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

// const APP_INNER = document.getElementsByClassName('AppInner')[0]
// const host = window.location.hostname
// let LOADING_TIME = 4000
// if (host === 'localhost') {
//   LOADING_TIME = 1
// }

// if (window.performance) {
//   if (performance.navigation.type === 1) {
//     localStorage.removeItem('loading')
//   }
// }

// if (localStorage.getItem('loading') !== '1') {
//   const startTime = new Date().getTime()
//   const loading = document.getElementsByClassName('loading')[0]
//   document.body.style.overflow = 'hidden'
//   loading.style.opacity = '1'
//   loading.style.display = 'flex'
//   window.addEventListener('DOMContentLoaded', () => {
//     const loadEnd = () => {
//       APP_INNER.style.display = 'block'
//       loading.style.opacity = '0'
//       localStorage.setItem('loading', '1')
//       setTimeout(() => {
//         loading.style.display = 'none'
//         document.body.style.overflow = 'visible'
//       }, 600)
//     }
//     const endTime = new Date().getTime()
//     if (endTime - startTime <= LOADING_TIME) {
//       setTimeout(loadEnd, LOADING_TIME - (endTime - startTime))
//     } else {
//       loadEnd()
//     }
//   })
// } else {
//   APP_INNER.style.display = 'block'
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
