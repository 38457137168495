import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ContentsSingle from '../containers/ContentsSingle'

class Single extends Component {
  constructor(props) {
    super()
    this.category = props.category
    this.path = props.history.location.pathname // 現在のパス
  }

  render() {
    const { data } = this.props
    const parentPath = new RegExp(this.category, 'g')
    const childPath = this.path.replace(parentPath, '').replace(/\//g, '')
    const selectedData = Array.from(data).filter(e => e.slug === childPath)[0]

    // CONTENTS singleテンプレート
    if (this.category === 'contents') {
      return <ContentsSingle data={selectedData} />
    }
    return null
  }
}

export default withRouter(Single)

Single.propTypes = {
  data: PropTypes.array,
  category: PropTypes.string,
  history: PropTypes.object,
}

Single.defaultProps = {
  data: null,
  category: null,
  history: null,
}
