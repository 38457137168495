export default {
  SITENAME: 'MID KNITE MAGAZINE',
  HOME: {
    HOMETEXT: 'HOME',
    MAINTEXT: 'READ THE WORLD',
    SUBTEXT:
      '東京の街角から、世界大紀行まで。<br>いろんな形の「旅」がつまったウェブマガジン。',
  },
  ABOUT: {
    TITLE: 'ABOUT',
    TEXT:
      '<p><b>MID KNITE MAGAZINE</b></p><p>東京の街角から、世界大紀行まで。MID KNITE JAPAN INTERNATIONAL LTD.唯一のオウンドメディアとして、「旅」をテーマにしたコンテンツをお届けします。</p><p>Mail: mail@midknitejapan.com<br>Twitter: <a href="https://twitter.com/midknitemgzn" target="_blank">https://twitter.com/midknitemgzn</a><br>Instagram: <a href="https://www.instagram.com/midknitemagazine/" target="_blank">https://www.instagram.com/midknitemagazine/</a></p>',
  },
  WORLDSHOP: {
    TITLE: 'WORLD SHOP',
    TEXT:
      '<p>WORLD SHOPでは、旅をキーワードにしたアイテムをご紹介しています。</p>',
    CATEGORY1: 'BOOK',
    CATEGORY2: 'GOOD TO GO',
    CATEGORY3: 'LUGGAGE',
    CATEGORY4: 'OVERSEAS LIFESTYLE',
  },
  CATEGORY: {
    international: '<p>海外の旅、話題をお届けします。</p>',
    domestic: '<p>国内の旅、話題をお届けします。</p>',
    info: '<p>MID KNITE MAGAZINEからのお知らせ。</p>',
    worldwide: '<p>MID KNITE MAGAZINE基幹企画。世界各地から最新リポート。</p>',
    madeinjapan:
      '<p>日本全国各地をまわり、旅先からフレッシュな話題をリポート。</p>',
    sfc_challenge: '<p>2021年、SFC取得を目指しおこなった修行記録！</p>',
    goodbyeworld:
      '<p>2004年〜2019年の15年間で70カ国、220都市以上を旅した「私」が、旅の思い出や旅におすすめのアイテムを紹介！</p>',
    tokyoite:
      '<p>Tokyoite = 東京人。東京生まれ、東京育ちの「私」が東京の魅力を再発見！</p>',
    dailyworld: '<p>旅人にとって有益となる日々のトピックをお届け！</p>',
    wordworld: '<p>言葉にまつわるトピックをお届け。旅の助けになるフレーズも。</p>',
    goodtogo: '<p>準備万端、さあ出かけよう！</p>',
    tarotworld: '<p>タロットカードで毎月のおすすめ旅行先を占う！</p>',
    tickettothesky:
      '<p>大学時代から航空マイレージを貯めはじめ、いわゆる”マイラー”として、これまで130万マイル以上を航空券へと交換。”旅の歴史にマイルあり”ということで、マイレージネタを中心に、実際に私がマイレージで発券した航空券をただただ紹介したりしていくコーナー。</p>',
    gutereise:
      '<p>2011年、ドイツ・デュッセルドルフのフリーペーパーで連載していた「Gute Reise!〜Von Düssel nach Europa〜」をWEB掲載！</p>',
    revolucion:
      '<p>2009年に開催した「MK WORLD TOUR 2009 REVOLUCIÓN」ツアー日記。</p>',
    trekkingworld: '<p>おすすめのトレッキングエリアを紹介！</p>',
  },
  NOTFOUND: {
    TEXT: 'PAGE NOT FOUND',
  },
  COPYRIGHT: '&copy;MID KNITE JAPAN INTERNATIONAL LTD. ALL RIGHTS RESERVED.',
}
