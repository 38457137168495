/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import GLOBAL from '../Global'
import worldshop from '../images/worldshop.png'
import SideBar from '../components/SideBar'
import GoogleAdFooter from '../utils/GoogleAdFooter'

const About = () => {
  return (
    <>
      <div className="mainHeading onlyEnglish">
        <h1>{GLOBAL.WORLDSHOP.TITLE}</h1>
      </div>
      <div className="mainContent">
        <div className="mainContainer">
          <div className="mainBody">
            <p>
              <img src={worldshop} alt="" />
            </p>
            <div dangerouslySetInnerHTML={{ __html: GLOBAL.WORLDSHOP.TEXT }} />
            <h2 className="heading04">{GLOBAL.WORLDSHOP.CATEGORY1}</h2>
            <ul className="shoppingList">
              <li>
                <iframe title="product101" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B08K3QLPCR&linkId=5409fe5a552c4f86e5318f2e42be68fe&bc1=000000&lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product102" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=0995799873&linkId=efa46ecdc7b866be08459315315ab2a1&bc1=000000&lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
            </ul>
            <h2 className="heading04">{GLOBAL.WORLDSHOP.CATEGORY2}</h2>
            <ul className="shoppingList">
              <li>
                <iframe title="product201" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B087WJF2X4&linkId=8d07827a3e287ff16357ea98f53f21e7&bc1=000000&lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product202" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=wmidknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B01BSKPU14&linkId=3330fa03d4d4007e2bf9f4f239e8ac5f&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product203" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B089D3CRHT&linkId=2da4948ab66ddba553910be4ca0d2fc2&bc1=000000&lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product204" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B01HBK83YQ&linkId=bbd361021527cc7a3b19cebb0e82096f&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product205" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B001NCDE7A&linkId=9c58ef024c8315072be1b379819f8471&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product206" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B001NCDE8O&linkId=eee38e91664dc6b08326c325550a810d&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product207" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=qf_sp_asin_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B01N5JPHG7&linkId=2c4c5fb8f786827465fe2839c1020565&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product208" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=qf_sp_asin_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B01N0V4BUJ&linkId=70224c2450e1401ac37386de09432c31&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product209" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=worldwebmagaz-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B01CMD4VKC&linkId=db74d0f4ee9a3ae18501234cb9ce7ad4&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product210" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=worldwebmagaz-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B0774FRVNW&linkId=8f46ddae996590b75c55934c4a439346&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
            </ul>
            <h2 className="heading04">{GLOBAL.WORLDSHOP.CATEGORY3}</h2>
            <ul className="shoppingList">
              <li>
                <iframe title="product301" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B073M5D9QL&linkId=0848a416ab84c83f9f38a8d2824c564a&bc1=000000&lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product302" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B07DPPV2P5&linkId=8601a4f77a7fc4c2b100f4cedad4da75&bc1=000000&lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product303" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B07S623HG8&linkId=e440782e728e5682d79df3bfbaa389c2&bc1=000000&lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
            </ul>
            <h2 className="heading04">{GLOBAL.WORLDSHOP.CATEGORY4}</h2>
            <ul className="shoppingList">
              <li>
                <iframe title="product401" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B0000AN3QI&linkId=a76a8bf7d587654e92b4a5106584b67f&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product402" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B00XMQL65M&linkId=440476fa1b696dea09960ab55b4af418&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product403" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B07MFV2ZY9&linkId=40132e4acc1a2addd69e1d66ba999ea9&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product404" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B0089IUFT6&linkId=f1fe1250fd84a1f29532e238384a1b4e&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
              <li>
                <iframe title="product405" style={{ width:'120px' ,height: '240px'}} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="https://rcm-fe.amazon-adsystem.com/e/cm?ref=tf_til&t=midknitemagazine-22&m=amazon&o=9&p=8&l=as1&IS2=1&detail=1&asins=B07L4MC5R7&linkId=268efb21443411ba5d6a10080ac575e3&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr" />
              </li>
            </ul>
            <div className="adBox">
              <GoogleAdFooter />
            </div>
          </div>
          <SideBar />
        </div>
      </div>
    </>
  )
}

export default withRouter(About)
