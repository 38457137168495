import React, { Component } from 'react'
import _ from 'lodash'
import axios from 'axios'
import PropTypes from 'prop-types'
import '../css/SideBar.css'
import GoogleAdSide from '../utils/GoogleAdSide'
// import FB from '../utils/Facebook'
import mkj from '../images/banner_mkj.png'
import mk from '../images/banner_mk.png'
import hmk from '../images/banner_humankind.png'
import midkniteathletics from '../images/banner_midkniteathletics.png'
import midknitetreks from '../images/banner_midknitetreks.png'
import midkntitecreation from '../images/banner_midknitecreation.png'

const END_POINT =
  'https://mk-official-data.firebaseio.com/contents.json?orderBy="show"&startAt="show"&limitToLast=5'
// const END_POINT ='/data/contents.json'

class SideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contents: [],
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      const datas = []
      Object.keys(result.data).forEach(value => {
        datas.push(result.data[value])
      })
      const data = datas.filter(e => e !== null && e.show !== 'hidden')
      this.setState({ contents: data })
    })
  }

  render() {
    const { contents } = this.state
    const { page } = this.props

    const data = _.orderBy(contents, ['generalId'], ['desc'])
    return (
      <div className="sideBar">
        <ul className="bannerS">
          <li>
            <a
              href="https://midknitejapan.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mkj} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://michihirokoseki.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mk} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://humankind.tokyo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={hmk} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://midkniteathletics.web.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={midkniteathletics} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://midknitetreks.web.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={midknitetreks} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://midknitecreation.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={midkntitecreation} alt="" />
            </a>
          </li>
        </ul>
        <div className="adBox">
          <GoogleAdSide />
        </div>
        {page === 'home' && (
          <>
            <div className="amazonBanner">
              <iframe
                title="amazonLink"
                src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=12&l=ez&f=ifr&linkID=44ef14d833b1aee78141dc073b8df7b2&t=worldwebmagaz-22&tracking_id=worldwebmagaz-22"
                width="300"
                height="250"
                scrolling="no"
                border="0"
                marginWidth="0"
                frameBorder="0"
              />
            </div>
            {/* <div className="amazonBanner">
              <FB />
            </div> */}
          </>
        )}
        {page !== 'home' && (
          <div className="sideContents">
            <h2 className="heading02">HOT ISSUE</h2>
            {data.map((e, i) => {
              if (i < 5) {
                const link = `/contents/${e.slug}`
                // const tag = e.tag.toUpperCase()
                let thumbnail
                if (e.thumbnail && e.thumbnail !== '') {
                  thumbnail = e.thumbnailUrl
                } else {
                  thumbnail = e.imageUrl
                }
                return (
                  <a href={link} key={e.slug} className="listPart">
                    {/* <span className={`tag ${e.tag}`}>{tag}</span> */}
                    <li>
                      <div className="thumbnail">
                        <img src={thumbnail} alt={e.title} />
                      </div>
                      <div className="titleBox">
                        {e.title}
                        {/* <span className="date">{e.date}</span> */}
                      </div>
                    </li>
                  </a>
                )
              }
              return ''
            })}
          </div>
        )}
      </div>
    )
  }
}

SideBar.propTypes = {
  page: PropTypes.string.isRequired,
}

export default SideBar
