/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import '../css/Home.css'
import axios from 'axios'
import Contents from './Contents'
import SideBar from '../components/SideBar'
import GoogleAdFooter from '../utils/GoogleAdFooter'
// import FB from '../utils/Facebook'
import GLOBAL from '../Global'
import MyButton from '../modules/MyButton'

class Home extends Component {
  constructor() {
    super()
    this.state = {
      instaData: [],
    }
  }

  componentDidMount() {
    document.title = GLOBAL.SITENAME

    const userName = 'midknitemagazine' // ビジネスorクリエイターアカウントの必要あり
    const accessToken =
      'EAARqtSbMqQUBAMX4ABTkTDmwIPiUmtAxT2RMOZA4vrpSZAZCEx1ZAVxmnVjK49dXe1JMjZBY8zNN8TZCstY3ovTOJbg9hJzxCdnjfFL32yIviLQAJS7pYdATVoNanlHp8r1nxj8vnkWwtyAZAPkZCISR1sSPtdR10TY4sHAWWzPj37355jywCGcH'
    const userId = '17841447339335611'
    const getCount = 6 // 取得したい投稿数
    axios
      .get(
        `https://graph.facebook.com/v12.0/${userId}?fields=business_discovery.username(${userName}){id,followers_count,media_count,ig_id,media.limit(${getCount}){caption,media_url,like_count}}&access_token=${accessToken}`
      )
      .then(res => {
        this.setState({ instaData: res.data })
      })
  }

  render() {
    const { instaData } = this.state
    // console.log(instaData.business_discovery && instaData.business_discovery.media.data)
    return (
      <main>
        <div className="main home">
          <div className="mainInner">
            <h1 className="mainVisual">{GLOBAL.HOME.MAINTEXT}
              <span dangerouslySetInnerHTML={{ __html: GLOBAL.HOME.SUBTEXT }} />
            </h1>
            <div className="mainContainer">
              <div className="mainBody">
                <div className="contents">
                  <Contents max="6" />
                  <MyButton link="/contents" text="more" />
                  <div className="twitterBox">
                    <h2>Twitter</h2>
                    <TwitterTimelineEmbed
                      sourceType="profile"
                      screenName="midknitemgzn"
                      options={{ height: 400 }}
                      // onComplete={action}
                    />
                  </div>
                  <div className="twitterBox">
                    <h2>Instagram</h2>
                    <ul className="list archive">
                      {instaData.business_discovery &&
                        instaData.business_discovery.media.data.map(data => {
                          return (
                            <a
                              href="https://www.instagram.com/midknitemagazine/"
                              rel="noopener noreferrer"
                              target="_blank"
                              className="listPart"
                              key={data.id}
                            >
                              <li>
                                <div className="thumbnail">
                                  <img src={data.media_url} alt="instagram" />
                                </div>
                              </li>
                            </a>
                          )
                        })}
                    </ul>
                  </div>
                  {/* <div className="twitterBox">
                    <h2>Facebook</h2>
                    <FB />
                  </div> */}
                </div>
              </div>
              <SideBar page="home" />
            </div>
          </div>

          <div className="adBox">
            <GoogleAdFooter />
          </div>
        </div>
      </main>
    )
  }
}

export default withRouter(Home)
