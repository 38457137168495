import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'
import GLOBAL from '../Global'

class BreadCrumb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    const endPoint = 'https://mk-official-data.firebaseio.com/contents.json'
    axios.get(endPoint).then(result => {
      this.setState({ data: result.data })
    })
  }

  render() {
    const {
      state: { data },
      props: { location },
    } = this
    const splitPath = location.pathname.split('/').filter(e => e !== '')
    return (
      <Breadcrumbs
        className="breadCrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/">
          {GLOBAL.HOME.HOMETEXT}
        </Link>
        {splitPath.map((element, i) => {
          if (i + 1 >= splitPath.length) {
            if (i === 0) {
              const categoryName = element.toUpperCase()
              return (
                <Typography key={element} color="textPrimary">
                  {categoryName}
                </Typography>
              )
            }
            const matchData = Array.from(data).filter(e => e.slug === element)
            if (matchData[0]) {
              return (
                <div key={element}>
                  <Typography key={element} color="textPrimary">
                    {matchData[0].title}
                  </Typography>
                </div>
              )
            }
            return null
          }
          const categoryName = element.toUpperCase()
          return (
            <Link key={element} color="inherit" href={`/${element}`}>
              {categoryName}
            </Link>
          )
        })}
      </Breadcrumbs>
    )
  }
}

export default withRouter(BreadCrumb)

BreadCrumb.propTypes = {
  location: PropTypes.object,
}
BreadCrumb.defaultProps = {
  location: null,
}
