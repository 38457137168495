/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import _ from 'lodash'
import axios from 'axios'
import Pagination from '@material-ui/lab/Pagination'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress'
import SideBar from '../components/SideBar'
import GoogleAdFooter from '../utils/GoogleAdFooter'
import GLOBAL from '../Global'

let keepState = ''
let keepCountry = ''
let keepNet = ''
let keepCategory = ''
let keepPage = ''

const END_POINT = '/data/countries.json'
const END_POINT2 = '/data/network.json'
const END_POINT3 = '/data/category.json'

class ContentsList extends Component {
  constructor() {
    super()
    this.state = {
      stateData: [],
      current: keepState !== '' ? keepState : 'all',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midknitejapan: true,
      midkniteblue: true,
      countries: [],
      network: [],
      category: [],
      page: keepPage !== '' ? keepPage : 1,
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      this.setState({ countries: result.data })
    })
    axios.get(END_POINT2).then(result => {
      this.setState({ network: result.data })
    })
    axios.get(END_POINT3).then(result => {
      this.setState({ category: result.data })
    })
    const { data } = this.props
    if (data.length === 0) {
      this.setState({ current: 'all' })
      keepState = ''
      this.setState({
        page: 1,
      })
      keepPage = ''
    }
    if (keepState === 'country') {
      this.country(data, keepCountry)
    } else if (keepState === 'network') {
      this.network(data, keepNet)
    } else if (keepState === 'category') {
      this.category(data, keepCategory)
    } else if (keepState !== '') {
      this[keepState](data)
    }
    if (keepPage !== '') {
      this.setState({
        page: keepPage,
      })
    }
  }

  handleChange = event => {
    const {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
    } = this.state
    const { data } = this.props
    const name = event.currentTarget.value
    const checked = event.target.checked
    this.setState({
      current: 'all',
      [name]: checked,
    })
    const selectedCategory = {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
      [name]: checked,
    }

    Object.keys(selectedCategory).forEach(key => {
      if (selectedCategory[key] === false) delete selectedCategory[key]
    })
    const categories = Object.keys(selectedCategory)

    const matchData = e => {
      const targetArrays = [...categories, ...e.areaCategory]
      const match = targetArrays.filter(
        item => categories.includes(item) && e.areaCategory.includes(item)
      )
      return match.length
    }

    const selectedData = data.filter(e => matchData(e) !== 0)

    this.setState({
      stateData: selectedData,
    })
  }

  removeAllCheck = () => {
    this.setState({
      midkniteeuro: false,
      midkniteusa: false,
      midkniteasia: false,
      midknitepacific: false,
      midknitelatin: false,
      midkniteafrica: false,
      midknitejapan: false,
      midkniteblue: false,
    })
  }

  stateInitialize = () => {
    this.setState({
      current: 'all',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midknitejapan: true,
      midkniteblue: true,
      page: 1,
    })
  }

  all(data) {
    this.stateInitialize()
    this.setState({ stateData: data })
  }

  international(data) {
    this.stateInitialize()
    const international = data.filter(e =>
      e.parentCategory.includes('international')
    )
    this.setState({ stateData: international, current: 'international' })
  }

  domestic(data) {
    this.stateInitialize()
    const domestic = data.filter(e => e.parentCategory.includes('domestic'))
    this.setState({ stateData: domestic, current: 'domestic' })
  }

  info(data) {
    this.stateInitialize()
    const info = data.filter(e => e.brandCategory.includes('info'))
    this.setState({ stateData: info, current: 'info' })
  }

  country(data, countryName) {
    this.stateInitialize()
    const country = data.filter(
      e => e.countryCategory && e.countryCategory.includes(countryName)
    )
    this.setState({ stateData: country, current: countryName })
  }

  network(data, networkName) {
    this.stateInitialize()
    const network = data.filter(
      e => e.areaCategory && e.areaCategory.includes(networkName)
    )
    this.setState({ stateData: network, current: networkName })
  }

  category(data, categoryName) {
    this.stateInitialize()
    const category = data.filter(
      e => e.brandCategory && e.brandCategory.includes(categoryName)
    )
    this.setState({ stateData: category, current: categoryName })
  }

  activeClass(category) {
    const { current } = this.state
    if (category === current) {
      return 'current'
    }
    return null
  }

  handlePage(e, value) {
    this.setState({ page: value })
    window.scrollTo(0, 0)
    keepPage = value
  }

  render() {
    const {
      stateData,
      current,
      toggle,
      countries,
      network,
      category,
      page,
    } = this.state

    if (countries.includes(current)) {
      keepState = 'country'
      keepCountry = current
    } else {
      keepState = current
    }
    network.forEach(e => {
      if (e.slug.includes(current)) {
        keepState = 'network'
        keepNet = current
      }
    })
    category.forEach(e => {
      if (e.slug.includes(current)) {
        keepState = 'category'
        keepCategory = current
      }
    })
    const { data, max } = this.props
    let selectedData
    if (stateData.length === 0) {
      selectedData = data
    } else {
      selectedData = stateData
    }
    const allData = _.orderBy(selectedData, ['generalId'], ['desc'])
    // const allData = selectedData.concat().reverse()
    let reverseData = allData.filter(e => e !== undefined || e !== null)
    reverseData = reverseData.filter(e => e && e.dependency !== 'child')

    const dataLength = reverseData.length
    let perPage = 18
    if (max) perPage = max
    let pageNumber = 1
    if (dataLength > perPage) {
      pageNumber = parseInt(dataLength / perPage, 10) + 1
      reverseData = reverseData.slice(perPage * (page - 1), perPage * page)
    }
    if (dataLength === 0) {
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )
    }
    const categoryImage = `/images/category/${current}.jpg`

    return (
      <div>
        {!max && (
          <>
            <div className="tourCategory">
              <Button
                className={current === 'all' ? 'current' : ''}
                variant="contained"
                onClick={() => {
                  this.all(data)
                }}
              >
                ALL
              </Button>
              <Button
                className={current === 'international' ? 'current' : ''}
                disabled={
                  data.filter(e => e.parentCategory.includes('international'))
                    .length === 0
                }
                variant="contained"
                onClick={() => {
                  this.international(data)
                }}
              >
                INTERNATIONAL
              </Button>
              <Button
                className={current === 'domestic' ? 'current' : ''}
                disabled={
                  data.filter(e => e.parentCategory.includes('domestic'))
                    .length === 0
                }
                variant="contained"
                onClick={() => {
                  this.domestic(data)
                }}
              >
                DOMESTIC
              </Button>
              <Button
                className={current === 'info' ? 'current' : ''}
                variant="contained"
                disabled={
                  data.filter(e => e.brandCategory.includes('info')).length ===
                  0
                }
                onClick={() => {
                  this.info(data)
                }}
              >
                INFO
              </Button>
            </div>

            <div className="accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>CATEGORY</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="tourCategorySmall">
                    {category.map(categoryName => {
                      return (
                        <Button
                          key={categoryName.slug}
                          className={
                            current === categoryName.slug
                              ? `current ${categoryName.slug}`
                              : categoryName.slug
                          }
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            this.category(data, categoryName.slug)
                          }}
                        >
                          {categoryName.name}
                        </Button>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* <div className="accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>MID KNITE JAPAN INTERNATIONAL NETWORK</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="tourCategorySmall">
                    {network.map(networkName => {
                      return (
                        <Button
                          key={networkName.slug}
                          className={
                            current === networkName.slug
                              ? `current ${networkName.slug}`
                              : networkName.slug
                          }
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            this.network(data, networkName.slug)
                          }}
                        >
                          {networkName.name}
                        </Button>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>COUNTRIES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="tourCategorySmall">
                    {countries.map(countryName => {
                      return (
                        <Button
                          key={countryName}
                          className={current === countryName ? 'current' : ''}
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            this.country(data, countryName)
                          }}
                        >
                          <img
                            src={`/images/flags/${countryName
                              .toLowerCase()
                              .replace(/\s+/g, '')}.png`}
                            alt={countryName}
                            className="scheduleFlag"
                          />
                          {countryName}
                        </Button>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div> */}
          </>
        )}

        <div className="mainContainer">
          <div className="mainBody">
            {current !== 'all' && (
              <div className="categoryDesc">
                <div>
                  <img src={categoryImage} alt={current} />
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: GLOBAL.CATEGORY[current] }}
                />
              </div>
            )}
            <ul className={`list archive${toggle ? ' listStyle' : ''}`}>
              {reverseData.map((e, i) => {
                const link = `/contents/${e.slug}`
                const tag = e.tag
                let thumbnail
                if (e.thumbnail && e.thumbnail !== '') {
                  thumbnail = e.thumbnailUrl
                } else {
                  thumbnail = e.imageUrl
                }

                return (
                  <Link to={link} key={e.slug} className="listPart">
                    <span className={`tag ${e.brandCategory[0]}`}>{tag}</span>
                    <li>
                      <div className="thumbnail">
                        <img src={thumbnail} alt={e.title} />
                      </div>
                      <div className="titleBox">
                        {e.title}
                        <span className="date">{e.date}</span>
                      </div>
                    </li>
                  </Link>
                )
              })}
            </ul>
            {!max && (
              <>
                <Pagination
                  count={pageNumber}
                  className="pagination"
                  page={page}
                  onChange={(e, value) => {
                    this.handlePage(e, value)
                  }}
                />
                <div className="adBox">
                  <GoogleAdFooter />
                </div>
              </>
            )}
          </div>
          {!max && <SideBar />}
        </div>
      </div>
    )
  }
}

export default withRouter(ContentsList)

ContentsList.propTypes = {
  data: PropTypes.array,
}

ContentsList.defaultProps = {
  data: null,
}
