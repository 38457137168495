import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const MyButton = styled(Button)({
  // background: 'linear-gradient(45deg, #4343C8 30%, #00008B 90%)',
  background: '#2C2C2C',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(146,146,146, .3)',
  color: 'white',
  width: 200,
  height: 48,
  margin: '20px auto',
  padding: '0 30px',
  display: 'block',
  '&:hover': {
    background: '#686868',
  },
})

export default function StyledComponents(props) {
  const { link, text } = props
  return (
    <MyButton className="myButton">
      <a className="buttonLinkText" href={link}>
        {text}
      </a>
    </MyButton>
  )
}

StyledComponents.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
}

StyledComponents.defaultProps = {
  link: null,
}
