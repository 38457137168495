/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import GLOBAL from '../Global'
import SideBar from '../components/SideBar'
import mkm from '../images/mkm.png'
import GoogleAdFooter from '../utils/GoogleAdFooter'

const About = () => {
  return (
    <>
      <div className="mainHeading onlyEnglish">
        <h1>{GLOBAL.ABOUT.TITLE}</h1>
      </div>
      <div className="mainContent">
        <div className="mainContainer">
          <div className="mainBody">
            <p>
              <img src={mkm} alt="" />
            </p>
            <div dangerouslySetInnerHTML={{ __html: GLOBAL.ABOUT.TEXT }} />
            <div className="adBox">
              <GoogleAdFooter />
            </div>
          </div>
          <SideBar />
        </div>
      </div>
    </>
  )
}

export default withRouter(About)
